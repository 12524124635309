import React from "react";
import RegisterForm from "./Form/RegisterForm";

const Header = () => {
  return <div>
        <div className="speakers-header">
            <div className="header-inner">
                <div className="container">
                    <div className="head-sec-1">
                        <div className="site-heading">
                            <h1 className="text-center">CONFIDENT SPEAKERS BLUEPRINT</h1>
                        </div>
                    </div>
                    <div className="head-sec-2">
                        <h3 className="text-center">6 hours Physical Classroom Training</h3>
                        <p className="text-center text-white">Join and become like successful leaders by <br/>
                                <span>mastering public speaking</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <RegisterForm/>
  </div>;
};

export default Header;
