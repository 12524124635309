import React from "react";
import "./RegisterForm.css" ;
// import {useState} from 'react';
import { Formik, Form, Field, ErrorMessage} from 'formik';
import { SignupSchema, Validation } from "../Validation";

const RegisterForm = () => {
  // const [value, setValue] = useState('');

  // const handleChange = event => {
  //   const result = event.target.value.replace(/\D/g, '');

  //   setValue(result);
  // };

  return <div>
        <Formik
            initialValues={Validation} 
            validationSchema={SignupSchema}
            onSubmit={(values ,action) =>
              setTimeout(() => {
                alert(JSON.stringify(values, null, 2));
                action.resetForm();
              })
            }
            render = {({ values }) => (
              <Form>
                <div className="register-form">
                  <div className="register-form-inner">
                    <div className="container">
                      <div className="content-head mb-4">
                        <h2 className="text-center text-white">Register Here</h2>
                      </div>
                      <div className="row">
                        <div className="col-md-3">
                          <div className="name-field">
                            <Field className="w-100" type="text" name="name" placeholder="Your Name" />
                            <p className="ms-3 mt-2"><ErrorMessage name="name"/></p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="email-field">
                            <Field className="w-100" type="email" name="email" placeholder="Email Address" />
                            <p className="ms-3 mt-2"><ErrorMessage name="email"/></p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="phone-field">
                            <Field className="w-100" type="text" name="phonenumber" placeholder="Mobile Number" maxLength={10} />
                            <p className="ms-3 mt-2"><ErrorMessage name="phonenumber"/></p>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="register-form-submit text-center">
                            <button type="submit">Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
        />
  </div>;
};

export default RegisterForm;
