import React from "react";

const Footer = () => {
  return <div>
      <div className="speakers-footer">
        <div className="speakers-footer-inner">
              <div className="container">
                <div className="footer-border-content">
                  <div className="content-head">
                    <h2 className="text-center">A Promise</h2>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="letter">
                        <div className="letter-heading">
                          <h5 className="text-white text-center">No Questions Asked Money Back Guarantee</h5>
                        </div>
                        <div className="letter-content">
                          <p className="text-white mb-3">Dear Sir / Ma'am,</p>
                          <p className="text-white mb-3">I am here to help you grow your confidence and make you a great speaker, 
                            and I am here to share extremely valuable secrets and insights which have
                            impacted Million+ people overall, and I personally ensure everyone who comes 
                            and attends my workshops gets more than they expected.
                          </p>
                          <p className="text-white mb-3">If after attending the workshop, you still don't find enough value for some reason, 
                              I take complete responsibility and ownership of that and hence, I promise to give a
                              complete refund of the workshop amount of Rs 2500. All you have to do is mail to
                              me at saurabh@saurabhchharia.com and my team will ensure you are provided with a 
                              refund in the shortest amount of time.
                          </p>
                          <p className="text-white mb-2">Your's __________</p>
                          <p className="text-white">Saurabh Chharia</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
      </div>
  </div>;
};

export default Footer;
