import * as Yup from 'yup';
export const SignupSchema = Yup.object().shape({    
    name : Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('This Feild is Required'),
    email: Yup.string().email('This email does not Exists').required('This Field is Required'),
    phonenumber : Yup.string().matches(/^[0-9\b]+$/ , 'Please enter numbers').required('This Feild is Required'),
});

export const Validation = {
    name : "" ,
    email : "" ,
    phonenumber : "",
}