import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import "./Modalstyles.css";
import CountdownTimer from "./CountdownTimer/CountdownTimer";
import RegisterForm from "./Form/RegisterForm";



Modal.setAppElement("#root");


const Content = () => {
    const [isOpen, setIsOpen] = useState(false);
    function toggleModal() {
        setIsOpen(!isOpen);
    }
    const THREE_DAYS_IN_MS = 1 * 24 * 60 * 60 * 1000;
    const NOW_IN_MS = new Date().getTime();
    const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

    const arrow = ">>>"
    return <div onClick={(e) => { e.stopPropagation(); }}>
        <Modal
            isOpen={isOpen}
            onRequestClose={toggleModal}
            contentLabel="My dialog"
            overlayClassName="myoverlay"
            className="mymodal ww">
            <div className="modal-register-form">
                <div className="close-button">
                    <button type="close" className="text-danger" onClick={toggleModal}>X</button>
                </div>
                <RegisterForm/>
            </div>
        </Modal>
        <div className="site-content">
            <div className="site-content-inner">
                <div className="content-sec-1">
                    <div className="content-sec-1-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">What happens when you join?</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="image-blk">
                                        <img src={require("../Components/images/image-blk-img.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="valpoints-blk">
                                        <div className="point-1">
                                            <p className="text-white">You can <span>Express yourself confidently</span> without any Fear <br /> in front of anyone</p>
                                        </div>
                                        <div className="point-2">
                                            <p className="text-white">You can handle any <span>presentation or speech confidently</span> <br /> even though you are not prepared</p>
                                        </div>
                                        <div className="point-3">
                                            <p className="text-white">You can experience <span>Career Growth with Promotions</span><br /> and onsite opportunities as you become confident</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-2">
                    <div className="content-sec-2-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">What happens when you join?</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="detail-blk">
                                        <div className="detail-1">
                                            <p className="text-center text-white">STARTS ON <span>8th NOVEMBER 2022</span> (10:00 AM - 12:30 PM)</p>
                                        </div>
                                        <div className="detail-2">
                                            <p className="text-center text-white">Language - Basic English</p>
                                        </div>
                                        <div className="detail-3 d-flex justify-content-center">
                                            <button className="text-white" onClick={toggleModal}>Join Now</button>
                                        </div>
                                        <div className="detail-4">
                                            <p className="text-center text-white">Register In Next 15:00 Mins</p>
                                        </div>
                                        <CountdownTimer targetDate={dateTimeAfterThreeDays} />

                                        <div className="detail-5 d-none justify-content-evenly align-items-center">
                                            <div className="circles"><p className="text-center text-white">01 <br /> <span>Day</span></p></div>
                                            <div className="circles"><p className="text-center text-white">14 <br /> <span>Hours</span></p></div>
                                            <div className="circles"><p className="text-center text-white">28 <br /> <span>Minutes</span></p></div>
                                            <div className="circles"><p className="text-center text-white">14 <br /> <span>Seconds</span></p></div>
                                        </div>
                                        <div className="detail-6">
                                            <p className="text-center text-white">To Unlock Bonuses Worth Rs 6,487</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="coach-blk">
                                        <div className="coach-img text-center">
                                            <img src={require("../Components/images/detail-blk-img.png")} alt="" />
                                        </div>
                                        <div className="coach-details">
                                            <h2 className="text-center">SAURABH CHHARIA</h2>
                                            <p className="text-center text-white">Trained over <span>1,00,000</span>
                                                Professions in Public Speaking <br />
                                                in last 10 years
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-3">
                    <div className="content-sec-3-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">Saurabh's Clients Works for Companies Like</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-2 col-sm-4">
                                    <div className="companies text-center">
                                        <img src={require("../Components/images/tcs.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-4">
                                    <div className="companies text-center">
                                        <img src={require("../Components/images/microsoft.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-4">
                                    <div className="companies text-center">
                                        <img src={require("../Components/images/google.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-4">
                                    <div className="companies text-center">
                                        <img src={require("../Components/images/amazon.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-4">
                                    <div className="companies text-center">
                                        <img src={require("../Components/images/infosys.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-2 col-sm-4">
                                    <div className="companies text-center">
                                        <img src={require("../Components/images/accenture.png")} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-4">
                    <div className="content-sec-4-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">What will change in you as a person?</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="chart-image text-end">
                                        <p className="text-start text-white second-point">Right Tools and Techniques</p>
                                        <img src={require("../Components/images/chart-blkimg.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="chart-point1">
                                        <p className="text-white first-point">Right Mindset to Speak on Stage</p>
                                    </div>
                                    <div className="chart-point2">
                                        <p className="text-white third-point">Practical Exposure to stage</p>
                                    </div>
                                </div>
                            </div>
                            <div className="chart-join d-flex justify-content-center">
                                <button className="text-white" onClick={toggleModal}>Join Now</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sub-4">
                    <div className="content-sub-4-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">What will change in you as a person?</h2>
                            </div>
                            <div className="triangle-blk">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="rectangle-1 similar-rect">
                                            <p className="text-white"><span className="me-4">1</span>Right Mindset to Speak on Stage</p>
                                        </div>
                                        <div className="rectangle-2">
                                            <p className="text-white"><span className="me-4">2</span>Right Tools and Techniques</p>
                                        </div>
                                        <div className="rectangle-3 similar-rect">
                                            <p className="text-white"><span className="me-4">3</span>Practical Exposure to stage</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="chart-join d-flex justify-content-center">
                                    <button className="text-white" onClick={toggleModal}>Join Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-5">
                    <div className="content-sec-5-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">Who this workshop will help the best?</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6 col-sm-6">
                                    <div className="three-points-left">
                                        <img src={require("../Components/images/dont-join.png")} alt="" />
                                        <div className="image-resp">
                                            <h2 className="text-center text-white">Don't <br />Join If</h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-sm-6">
                                    <div className="three-points-right">
                                        <div className="img-point1 growth-blk d-flex align-items-center">
                                            <div className="number1 number-marg">
                                                <p className="text-white">1</p>
                                            </div>
                                            <div className="text1 second-text">
                                                <p>You are not serious about your <br /> career growth</p>
                                            </div>
                                        </div>
                                        <div className="img-point2 growth-blk d-flex align-items-center">
                                            <div className="number2 number-marg">
                                                <p className="text-white">2</p>
                                            </div>
                                            <div className="text2 second-text">
                                                <p>You are not an action taker</p>
                                            </div>
                                        </div>
                                        <div className="img-point3 growth-blk d-flex align-items-center">
                                            <div className="number3 number-marg">
                                                <p className="text-white">3</p>
                                            </div>
                                            <div className="text3 second-text">
                                                <p>You never aspire to be called a <br /> great speaker</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="register-now d-flex justify-content-center">
                                <button className="text-white" onClick={toggleModal}>Register now at 2500 only</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-6">
                    <div className="content-sec-6-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">Unable to Decide</h2>
                                <p className="text-center text-white">listen from people whom i have helped to become a great speaker.</p>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="youtube1 text-center">
                                        <img src={require("../Components/images/youtube-1.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="youtube1 text-center">
                                        <img src={require("../Components/images/youtube-2.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="youtube1 text-center">
                                        <img src={require("../Components/images/youtube-3.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="youtube text-center">
                                        <img src={require("../Components/images/youtube-4.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="youtube text-center">
                                        <img src={require("../Components/images/youtube-5.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="youtube text-center">
                                        <img src={require("../Components/images/youtube-6.png")} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-7">
                    <div className="content-sec-7-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">Text Reviews</h2>
                            </div>
                            <div className="testimonial-block">
                                <div className="row justify-content-center">
                                    <div className="col-md-4 text-center">
                                        <img src={require("../Components/images/review1.png")} alt="" />
                                        <div className="testimonial-1 testimonial-back">
                                            <div className="star-blk d-flex justify-content-center align-items-center">
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                            </div>
                                            <div className="reviewer">
                                                <h4 className="text-center text-white">Shwetha </h4>
                                            </div>
                                            <div className="review">
                                                <p>If you are looking for best public speaking <br />training course in
                                                    Hyderabad india which <br />can transform you completely into a <br />
                                                    fearless dynamic speaker with lots of <br /> practical experience. go ahead <br />and visit <br />
                                                    <a href="http://www.saurabhchharia.com">http://www.saurabhchharia.com</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <img src={require("../Components/images/review2.png")} alt="" />
                                        <div className="testimonial-2 testimonial-back">
                                            <div className="star-blk d-flex justify-content-center align-items-center">
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                            </div>
                                            <div className="reviewer">
                                                <h4 className="text-center text-white">Mr Nagaprasad</h4>
                                            </div>
                                            <div className="review">
                                                <p>If you are looking for a Training in public <br /> speaking in Hyderabad which can <br />
                                                    transform you completely into a fearless <br /> dynamic speaker with lots of practical <br />
                                                    experience. go ahead and <br />visit
                                                    <a href="http://www.saurabhchharia.com">http://www.saurabhchharia.com</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <img src={require("../Components/images/review3.png")} alt="" />
                                        <div className="testimonial-3 testimonial-back">
                                            <div className="star-blk d-flex justify-content-center align-items-center">
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                            </div>
                                            <div className="reviewer">
                                                <h4 className="text-center text-white">Vivek Dubey</h4>
                                            </div>
                                            <div className="review">
                                                <p>if you are looking for a Training in public <br />speaking training course in
                                                    Hyderabad<br /> which can transform you completely into <br />a fearless dynamic speaker
                                                    with lots <br />of practical experience. go ahead and <br /> visit
                                                    <a href="http://www.saurabhchharia.com">http://www.saurabhchharia.com</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <img src={require("../Components/images/review4.png")} alt="" />
                                        <div className="testimonial-4 testimonial-back">
                                            <div className="star-blk d-flex justify-content-center align-items-center">
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                            </div>
                                            <div className="reviewer">
                                                <h4 className="text-center text-white"> Anushka </h4>
                                            </div>
                                            <div className="review">
                                                <p>Anushka is so happy after getting <br /> transformed into a great speaker.<br />
                                                    Even if you want to be transformed into <br />a great speaker.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <img src={require("../Components/images/review5.png")} alt="" />
                                        <div className="testimonial-5 testimonial-back">
                                            <div className="star-blk d-flex justify-content-center align-items-center">
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                            </div>
                                            <div className="reviewer">
                                                <h4 className="text-center text-white">Seema</h4>
                                            </div>
                                            <div className="review">
                                                <p>If you are looking for best public speaking <br />training course in
                                                    Hyderabad india which <br />can transform you completely into a <br />
                                                    fearless dynamic speaker with lots of <br /> practical experience
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-4 text-center">
                                        <img src={require("../Components/images/review6.png")} alt="" />
                                        <div className="testimonial-6 testimonial-back">
                                            <div className="star-blk d-flex justify-content-center align-items-center">
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                                <div className="stars"><i className="fa fa-star" aria-hidden="true"></i></div>
                                            </div>
                                            <div className="reviewer">
                                                <h4 className="text-center text-white">Mr Vikram</h4>
                                            </div>
                                            <div className="review">
                                                <p>If you are looking for a Training in public <br /> speaking in Hyderabad which can <br />
                                                    transform you completely into a fearless <br /> dynamic speaker with lots of practical <br />
                                                    experience.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-8">
                    <div className="content-sec-8-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">Meet Your Mentor</h2>
                                <h3 className="text-center">SAURABH CHHARIA</h3>
                            </div>
                            <div className="saurabh-image text-center mt-4">
                                <img src={require("../Components/images/saurabh.png")} alt="" />
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-3 col-sm-4">
                                    <div className="skills text-center merge-down">
                                        <img src={require("../Components/images/icon-1.png")} alt="" />
                                        <p className="text-center text-white"><span>10</span> years <br /> of experience in Training <br /> and Coaching</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <div className="skills text-center merge-down">
                                        <img src={require("../Components/images/icon-2.png")} alt="" />
                                        <p className="text-center text-white"><span>300+</span> Seminars <br />conducted in the <br /> last 5 years</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <div className="skills text-center merge-down">
                                        <img src={require("../Components/images/icon-3.png")} alt="" />
                                        <p className="text-center text-white"><span>1000+</span> <br /> Coaching Clients</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <div className="skills text-center no-border merge-down">
                                        <img src={require("../Components/images/icon-4.png")} alt="" />
                                        <p className="text-center text-white">Josh Talk Speaker</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <div className="skills text-center">
                                        <img src={require("../Components/images/icon-5.png")} alt="" />
                                        <p className="text-center text-white">Works with Celebrities <br />and Politicians</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <div className="skills text-center">
                                        <img src={require("../Components/images/icon-6.png")} alt="" />
                                        <p className="text-center text-white">Author of a Book</p>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <div className="skills text-center">
                                        <img src={require("../Components/images/icon-7.png")} alt="" />
                                        <p className="text-center text-white">Millions of views on <br />Youtube Videos</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-9">
                    <div className="content-sec-9-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">I am on a mission to make people speak confidently and
                                    comfortably without any hesitation or fear even if they have done
                                    it before in their life.
                                </h2>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-4 col-sm-6">
                                    <div className="group-images text-center down-marg">
                                        <img src={require("../Components/images/group-1.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="group-images text-center down-marg">
                                        <img src={require("../Components/images/group-2.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="group-images text-center down-marg">
                                        <img src={require("../Components/images/group-3.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="group-images text-center">
                                        <img src={require("../Components/images/group-4.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="group-images text-center">
                                        <img src={require("../Components/images/group-5.png")} alt="" />
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="group-images text-center">
                                        <img src={require("../Components/images/group-6.png")} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="content-head2">
                                <h2 className="text-center">because i have been a bad speaker myself in the past</h2>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="content-sec-10">
                    <div className="content-sec-10-inner">
                        <div className="container">
                            <div className="content-head">
                                <h2 className="text-center">What Top  Topics will be covered</h2>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-md-4 col-sm-6">
                                    <div className="message-blk text-center down-gap">
                                        <img src={require("../Components/images/new-icon-1.png")} alt="" />
                                        <p className="text-white text-center">Overcoming <br />Hesitation <br />and Fear</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="message-blk text-center down-gap">
                                        <img src={require("../Components/images/new-icon-2.png")} alt="" />
                                        <p className="text-white text-center">Structuring <br />content</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="message-blk text-center down-gap">
                                        <img src={require("../Components/images/new-icon-3.png")} alt="" />
                                        <p className="text-white text-center">Body <br />language</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="message-blk text-center">
                                        <img src={require("../Components/images/new-icon-4.png")} alt="" />
                                        <p className="text-white text-center">voice <br />Modulation <br />Secrets</p>
                                    </div>
                                </div>
                                <div className="col-md-4 col-sm-6">
                                    <div className="message-blk text-center">
                                        <img src={require("../Components/images/new-icon-5.png")} alt="" />
                                        <p className="text-white text-center">Confidence<br /> Secrets</p>
                                    </div>
                                </div>
                            </div>
                            <div className="pricing">
                                <h5 className="text-center text-white">Regular price <span className="text-danger ms-5">10,000</span></h5>
                                <h5 className="text-center text-white">Today's price <span className="green-color ms-5">2500</span></h5>
                            </div>
                            <div className="sec-10-register d-flex justify-content-center">
                                <button className="text-white" onClick={toggleModal}>Register now at 2500 only</button>
                            </div>
                        </div>
                    </div>
                </div>
                <RegisterForm/>
                <div className="content-sec-11">
                    <div className="content-sec-11-inner">
                        <div className="container">
                            <div className="content-head3">
                                <h2 className="text-center text-white">Still Not Sure? We got your Back!</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="left-content-blk">
                                        <h5>Our Guarantee</h5>
                                        <p className="text-white">Get this amazing offer today for just Rs 99,
                                            and get a money – back guarantee. Join today,
                                            go through our seminar and if you don't like
                                            it for any reason, simply send us an email and
                                            we'll refund every penny – no questions asked
                                        </p>
                                        <button className="text-white" onClick={toggleModal}    >YES! Save My Spot Now {arrow}</button>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="gurantee-blk">
                                        <img src={require("../Components/images/guarantee.png")} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>;
};

export default Content;
